import React, { useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import ShowMoreText from "react-show-more-text"
import { Swiper, SwiperSlide } from "swiper/react"
import config from "../../../config/config"

const QuickViewModalHomeDesignData = ({ show, handleClose, item, hidePricingGlobally }) => {
  const [slideOption, setSlideOption] = useState(null)
  const [isReadmore, setReadmore] = useState(true)
  const changePopupSlideImage = slide => {
    setSlideOption(slide)
  }
  const modal = useMemo(() => {
    const swiper_params = {
      spaceBetween: 10,
      navigation: {
        prevEl: ".swiper-button-prev-unique",
        nextEl: ".swiper-button-next-unique",
      },
      breakpoints: {
        1400: {
          slidesPerView: 5,
        },
        1200: {
          slidesPerView: 4,
        },
        992: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    }
    const host = config.host
    var isShowing = show
    const valid_status = ["Active"]
    var className = item.className
    var image_path = item.Image
    var isFloorplan = false
    var other_options = []
    let vt = null
    let link = item.link
    switch (className) {
      case "HomeDesign":
        isFloorplan = true;
        item.about = item.description
        var floorplans = item.Floorplans
        if (typeof floorplans !== undefined && floorplans) {
          var i = 0
          for (; floorplans[i];) {
            var option = floorplans[i].UndigitalFloorplan
            option.Image = option.groundFloorImagePath
            var isValid = valid_status.includes(option.status)
            if (isValid) {
              other_options.push(option)
            }
            i++
          }
        }
        if(item.vt){
            if(item.vt.groundFloorImagePath){
                image_path = item.vt.groundFloorImagePath
            }else if(item.vt.firstFloorImagePath){
                image_path = item.vt.firstFloorImagePath
            }
            link = `${item.link}${item.vt ? `/?vid=${item.vt.objectID}` : ''}`
        }
        break
      case "Cabinetry":
        var variations = item.Variations
        item.about = item.description
        if (typeof variations !== undefined && variations) {
          var j = 0
          for (; variations[j];) {
            var option = variations[j].UndigitalCabinetryVariation
            var rrpPrice = parseFloat(
              Math.round(option.rrPrice * 1000) / 1000
            ).toFixed(2)
            option.formattedPrice =
              "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            option.Image = option.featureImagePath ? option.featureImagePath : null
            var isValid = valid_status.includes(option.status)
            if (isValid) {
              other_options.push(option)
            }
            j++
          }
        }
        vt = item.vt
        if (vt) {
          image_path = vt.Image
        }
        break
      case "Furniture":
        var variations = item.Variations
        if (typeof variations !== undefined && variations) {
          var j = 0
          for (; variations[j];) {
            var option = variations[j].UndigitalFurnitureVariation
            var rrpPrice = parseFloat(
              Math.round(option.rrp * 1000) / 1000
            ).toFixed(2)
            option.formattedPrice =
              "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            option.Image = option.productImagePath ? option.productImagePath : null
            var isValid = valid_status.includes(option.status)
            if (isValid) {
              other_options.push(option)
            }
            j++
          }
        }
        vt = item.vt
        if (vt) {
          image_path = vt.Image
        }
        break
      case "Selection":
        var variations = item.Items
        if (typeof variations !== undefined && variations) {
          var j = 0
          for (; variations[j];) {
            var option = variations[j].UndigitalSelectionItem
            option.formattedPrice = null
            option.Image = option.productImagePath ? option.productImagePath : null
            var isValid = valid_status.includes(option.status)
            if (isValid) {
              other_options.push(option)
            }
            j++
          }
        }
        image_path = item.imagePath
        break
      case "Facade":
        var variations = item.Variations
        if (typeof variations !== undefined && variations) {
          var j = 0
          for (; variations[j];) {
            var option = variations[j].UndigitalFacadeVariation
            var rrpPrice = parseFloat(
              Math.round(option.rrPrice * 1000) / 1000
            ).toFixed(2)
            option.formattedPrice =
              "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            option.Image = option.featureImagePath ? option.featureImagePath : null
            var isValid = valid_status.includes(option.status)
            if (isValid) {
              other_options.push(option)
            }
            j++
          }
        }
        vt = item.vt
        if (vt) {
          image_path = vt.Image
        }
        break
      case "FinishesFixtures":
        var variations = item.Variations
        if (typeof variations !== undefined && variations) {
          var j = 0
          for (; variations[j];) {
            var option = variations[j].UndigitalFinishesFixturesVariation
            var rrpPrice = parseFloat(
              Math.round(option.rrp * 1000) / 1000
            ).toFixed(2)
            option.formattedPrice =
              "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            option.Image = option.featureProductImagePath ? option.featureProductImagePath : null
            var isValid = valid_status.includes(option.status)
            if (isValid) {
              other_options.push(option)
            }
            j++
          }
        }
        vt = item.vt
        if (vt) {
          image_path = vt.Image
        }
        break
      case "FeaturedProduct":
        break
      default:
        break
    }

    return <Modal
      show={isShowing}
      onHide={handleClose}
      onShow={resize}
      size={"xl"}
      animation={true}
      className="itemModal"
    >
      <div className="row mb-4">
      <div className="col-12 col-lg-12">
          <div
            className="btn-close d-lg-block"
            onClick={handleClose}
          ></div>
          <div className="list-group list-group-flush mb-4 custom-width">
            <h3 className="mb-1 border-bottom-4px text-center">{item.name}</h3>
            {item.about !== '' && (
              <div className="list-group-item px-0">
                <ShowMoreText
                  line={1}
                  more="Read more"
                  less="Read less"
                  className="content-wrap"
                  anchorClass="button-link d-block mt-1 text-right"
                  expanded={false}
                  width={0}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.about }} />
                </ShowMoreText>
              </div>
            )}
            {(item.rrp != 0 && hidePricingGlobally == 0) && (
              <div className="list-group-item px-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0">COST OVER STANDARD</h5>
                  </div>
                  <div className="col-auto">
                    <p className="font-weight-medium">{item.rrp}</p>
                  </div>
                </div>
              </div>
            )}
            <div className="text-center mt-4">
              <a href={slideOption ? `${item.link}?vid=${slideOption.objectID}` : link} className="btn btn-black btn-lg btn-w210">
                VIEW PRODUCT
            </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-12">
          <div className={`itemModal-wrap${isFloorplan ? ' is-floorplan' : ''}`}>
            {(slideOption || image_path) && (
              <img
                src={slideOption ? slideOption.Image : image_path}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      {other_options.length > 1 && (
        <div className={`other-available-wrap${isFloorplan ? ' is-floorplan' : ''}`}>
          <h3 className="main-title">Other available options: </h3>
          <div className="other-available">
            {other_options.length > 0 ? (
              <Swiper navigation {...swiper_params}>
                {other_options.map((item, idx) => (
                  <SwiperSlide key={`option-${idx}`}>
                    <div
                      onClick={() => changePopupSlideImage(item)}
                      className="product-items pointer"
                    >
                      <div className="product-items-thumb">
                        <img alt="" src={item.Image} alt={item.item} />
                      </div>
                      <p className="product-items-desc">
                        {item.name}
                        {hidePricingGlobally === 0 && (
                          <>
                            {item.name && item.formattedPrice ? " - " : ""}
                            {item.formattedPrice}
                          </>
                        )}
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <p>Loading...</p>
            )}
            <div className="swiper-button-prev-unique"></div>
            <div className="swiper-button-next-unique"></div>
          </div>
        </div>
      )}
    </Modal>
  }, [show, handleClose, item, hidePricingGlobally, slideOption])

  function resize() {
    window.dispatchEvent(new Event("resize"))
  }

  return <>{modal}</>
}

export default QuickViewModalHomeDesignData
